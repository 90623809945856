// src/components/Layout.js
import React from 'react';
import Header from '../../components/Header';
import { Grid } from "@mui/material";
import useWindowSize from '../../components/UseWindowSize';


const MainLayout = ({ children }) => {
  const { height: windowHeight } = useWindowSize();
  const landscapeMode = windowHeight < 550

  return (
    <Grid container direction='column'
      style={{ height: '100%', display: 'flex', flexWrap: 'nowrap' }}
    >
      <Header height={landscapeMode ? '15%' : '10%'} />
      <Grid item container height={landscapeMode ? '85%' : '90%'}>
        {children}
      </Grid>
    </Grid>
  );
};

export default MainLayout;
