/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, IconButton } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import ButtonBase from '@mui/material/ButtonBase';
import moment from 'moment';


moment.locale('fr');  // Set the locale to French

const theme = createTheme({
    typography: {
        fontFamily: "NouvelR",
    },
});


const getPictoStreamLink = (linkName) => {
    switch (linkName) {
        case 'Canal VOD':
            return 'picto_canal-vod';
        case 'Canal+':
            return 'picto_canal-plus';
        case 'Netflix':
            return 'picto_netflix';
        case 'Apple TV+':
            return 'picto_appletv';
        case 'Amazon Prime Video':
            return 'picto_prime';
        default:
            return '';
    }
}

const StreamLink = ({ name, url }) => {
    return (
        <Link href={url} color="inherit" underline="none" target="_blank" rel="noopener">
            <Grid item padding={0}>
                <Grid item container columnGap={1}>
                    <Grid item display='flex' alignItems='center'>
                        <img src={`images/${getPictoStreamLink(name)}.svg`} alt='vod' height='20px' />
                    </Grid>
                    <Grid item >
                        <Typography fontSize={12} align='center'>
                            {name}
                        </Typography>
                        {["Canal VOD", "Amazon Prime Video"].includes(name) && <Typography fontSize={12} align='center'>
                            dès <b>2,99€</b>
                        </Typography>}
                    </Grid>
                </Grid>
            </Grid>
        </Link>
    )
}

const FilmHorizontalCard = ({ film }) => {

    return (
        <ThemeProvider theme={theme}>
            <Grid container style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <Grid item style={{ display: 'flex' }}>
                    <img
                        src={film.img}
                        alt="Film poster"
                        width='200px'
                        style={{ display: 'block' }}
                        onError={(e) => { e.target.onerror = null; e.target.src = "https://affiches-films.s3.eu-west-3.amazonaws.com/cinematch_casse.png" }}
                    />
                </Grid>
                <Grid item style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '16px 16px 0' }}>
                    <Grid container flexDirection='row' columnGap={2}>
                        <Typography style={{ fontWeight: 700, fontSize: 24, whiteSpace: 'pre-line', textTransform: 'uppercase' }}>
                            {film?.title}
                        </Typography>
                        {(film?.DiagoScore) && <Grid style={{ border: '1px solid black', borderRadius: '10px', width: '40px', height: '40px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <Typography fontSize={20} fontWeight={700} style={{ color: 'rgba(102, 255, 0, 1)' }}>
                                {film?.DiagoScore}
                            </Typography>
                        </Grid>}
                    </Grid>
                    <Typography fontSize={16} style={{ marginTop: '8px', whiteSpace: 'pre-line' }}>
                        <b><u>Date de sortie</u></b> : {moment(film?.releaseDate, "YYYY/MM/DD").format("D MMMM YYYY")} | {film?.duration} | {film?.gender}
                        <br />
                        De <b>{film?.director}</b>, Avec <b>{film?.actors}</b>
                    </Typography>
                    <Typography fontSize={16} style={{ marginTop: '16px', whiteSpace: 'pre-line' }}>
                        <u>Lire le synopsis</u>
                    </Typography>
                    <Grid container justifyContent='space-around' height='75px' alignItems='center' flexWrap='nowrap'>
                        {
                            film?.streamingLinks?.map((link) =>
                                <StreamLink name={link.name} url={link.url} />
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </ThemeProvider >
    )
}

export default FilmHorizontalCard