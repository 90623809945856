/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Grid, IconButton, List, ListItem, ListItemText, TextField, Typography, Modal } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './style/scrollbar-styles.css';
import Typewriter from '../../../components/Typewriter';
import { LightTooltip } from '../../../components/Tooltip'
import ModalMail from '../../../components/ModalMail';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import { useTheme, useMediaQuery } from '@mui/material';
import lamejs from 'lamejs'; // Assurez-vous d'avoir installé cette bibliothèque via npm ou yarn
import useWindowSize from '../../../components/UseWindowSize';


const theme = createTheme({
    typography: {
        fontFamily: "NouvelR",
    },
});

const MediasMobileList = ({ medias, setLayout, setCurrentMedia, scrollToBottom, setIsMediaSelected }) => {

    const handleClick = (item) => {
        if (item !== undefined) {
            setLayout('fullscreenMedia')
            setCurrentMedia(item);
            setIsMediaSelected(true)
        }
    }

    useEffect(() => {
        scrollToBottom()
    }, [])

    return (
        <>
            <Grid container style={{ overflowX: 'auto', height: 'fit-content', width: 'calc(100vw - 78px)', position: 'absolute', marginTop: '13px' }}>
                <Grid container style={{ height: 'fit-content', width: 'fit-content', flexWrap: 'nowrap', columnGap: '8px', direction: 'rtl' }}>
                    {
                        medias.map((media) =>
                            // <div onClick={() => handleClick(media)} style={{ backgroundImage: `url(${media.img})`, backgroundColor: 'transparent', width: '137px', height: '181px', backgroundSize: 'contain', backgroundRepeat: 'round' }} />
                            <img
                                src={media.img}
                                alt="Film poster"
                                width='137px'
                                onClick={() => handleClick(media)}
                                style={{ display: 'block' }}
                                onError={(e) => { e.target.onerror = null; e.target.src = "https://affiches-films.s3.eu-west-3.amazonaws.com/cinematch_casse.png" }}
                            />
                        )
                    }
                </Grid>
            </Grid>
            <Grid height='190px' />
        </>
    )
}

const convertToMP3 = (wavBlob) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(wavBlob);
    reader.onload = () => {
        try {
            const arrayBuffer = reader.result;
            const wav = lamejs.WavHeader.readHeader(new DataView(arrayBuffer));
            const mp3encoder = new lamejs.Mp3Encoder(wav?.channels, wav?.sampleRate, 128);
            const mp3Data = [];
            const samples = new Int16Array(arrayBuffer, wav.dataOffset);
            const sampleBlockSize = 1152;
            for (let i = 0; i < samples.length; i += sampleBlockSize) {
                const sampleChunk = samples.subarray(i, i + sampleBlockSize);
                const mp3buf = mp3encoder.encodeBuffer(sampleChunk);
                if (mp3buf.length > 0) {
                    mp3Data.push(mp3buf);
                }
            }
            const mp3buf = mp3encoder.flush();
            if (mp3buf.length > 0) {
                mp3Data.push(mp3buf);
            }
            const mp3Blob = new Blob(mp3Data, { type: 'audio/mp3' });
            // Utilisez mp3Blob comme vous le souhaitez, par exemple, l'envoyer au serveur
            return mp3Blob;
        } catch (error) {
            // Ignorer l'erreur
        }
    };
};

const Chat = ({ chat, loadingMessage, handleChange, handleKeyPress, message, addMessage, setLayout, layout, setCurrentMedia, setIsMediaSelected }) => {
    const theme = useTheme();
    const { height } = useWindowSize();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const chatListRef = useRef(null);
    const [fullHeightDiv, setFullHeightDiv] = useState(document.documentElement.clientHeight);
    const [headerLoading, setHeaderLoading] = useState(true);
    useEffect(() => {
        if (!headerLoading) return;
        if (document.getElementById("header").offsetHeight) {
            setHeaderLoading(false);
            setFullHeightDiv(document.documentElement.clientHeight - document.getElementById("header").offsetHeight);
        }

    }, [headerLoading]);

    const scrollToBottom = () => {
        if (chatListRef.current) {
            chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
            // chatListRef.current.scrollTo({ top: chatListRef.current.scrollHeight, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        scrollToBottom()
    }, [chat]);


    const [recording, setRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    // const visualizerRef = useRef < HTMLCanvasElement > (null)
    const mediaRecorder = useRef(null);
    const [openMailModal, setOpenMailModal] = useState(false);
    const [typingComplete, setTypingComplete] = useState(Array(chat.length).fill(false));
    const [isTyping, setIsTyping] = useState(false); // Nouvel état pour suivre l'état de typage
    const [localMessage, setLocalMessage] = useState('');

    const handleStartRecording = async () => {
        setAudioBlob(null);
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorder.current = new MediaRecorder(stream);
        const chunks = [];
        mediaRecorder.current.ondataavailable = (e) => {
            chunks.push(e.data);
        };
        mediaRecorder.current.onstop = () => {
            const blob = new Blob(chunks, { 'type': 'audio/mp3' });
            setAudioBlob(blob);
            addMessage('ME', blob, 'audio');
        };
        mediaRecorder.current.start(1000);
        setRecording(true);
    };

    const handleStopRecording = () => {
        mediaRecorder.current.stop();

        setRecording(false);
        mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
    };


    const handleCloseModal = () => {
        setOpenMailModal(false)
    }

    const handleChangeLocal = (event) => {
        setLocalMessage(event.target.value);
    }

    const handleKeyPressLocal = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            addMessage('ME', localMessage);
            setLocalMessage(''); // Réinitialisez l'état local après l'envoi du message
        }
    };


    const handleTypingComplete = (index) => {
        setTypingComplete(prev => {
            const newTypingComplete = [...prev];
            newTypingComplete[index] = true;
            return newTypingComplete;
        });
        setIsTyping(false); // Réactiver le TextField lorsque le typage est terminé
    };

    const handleTypingStart = () => {
        setIsTyping(true); // Désactiver le TextField lorsque le typage commence
    };

    const [expandChat, setExpandChat] = useState(null)

    useEffect(() => {
        setLayout(expandChat ? 'fullscreenChat' : 'home')
    }, [expandChat])

    useEffect(() => {
        if (isMobile && chat.length > 1 && expandChat === null) {
            setExpandChat(true)
        }
    }, [chat])



    return (
        <ThemeProvider theme={theme}>
            <Grid container height='100%' style={{ flexDirection: 'column' }}>
                <Grid flex={1}>
                    {
                        isMobile && <Grid style={{ width: '100%', height: '37px', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1, borderRadius: '0 0 10px 10px', backgroundColor: 'rgba(41, 41, 41, 1)' }}>
                            <IconButton style={{ padding: 0 }}
                                onClick={() => {
                                    setExpandChat(!expandChat)
                                }}
                                aria-label="expand">
                                <img src="images/arrow_down.svg" alt="expand" style={{ transform: layout === 'home' && 'rotate(180deg)' }} />
                            </IconButton>
                        </Grid>
                    }
                    <Grid container direction='column' justifyContent='center' alignItems='flex-end' style={{ height: '100%', position: 'relative', backgroundColor: 'white' }}>
                        <Grid item style={{ position: 'absolute', overflowY: 'hidden', width: '100%', height: '100%' }}>
                            <List ref={chatListRef} sx={{ overflowY: 'auto', height: '100%' }}>
                                {chat.map((c, i) =>
                                    <ListItem key={i} sx={{ fontFamily: 'NouvelR', marginTop: i === 0 ? '20px' : 0, paddingRight: c.from === "assistant" ? 0 : '' }}>
                                        <Grid container>
                                            {c.from === "assistant" ?
                                                <Grid item xs={12}>
                                                    <ListItem sx={{ alignItems: 'flex-start', padding: { xs: '8px 0', md: '8px 16px' } }}>
                                                        <Avatar sx={{
                                                            bgcolor: "#000",
                                                        }}>
                                                            < img src="images/logo_cinematch-green.png" width='35px' alt="avatar" />
                                                        </Avatar>
                                                        <Grid item xs={10} style={{ padding: '4px 22px', position: 'relative' }}>
                                                            {i === 0 ? <span>{c.msg}</span> : <Typewriter text={c.msg} onTypingUpdate={scrollToBottom} onTypingComplete={() => handleTypingComplete(i)} onTypingStart={handleTypingStart} />}
                                                            {isMobile && layout === 'fullscreenChat' && c?.media && (
                                                                (i === chat.length - 1 ? typingComplete[i] : true) && (
                                                                    <div>
                                                                        <MediasMobileList
                                                                            medias={c?.media}
                                                                            setLayout={setLayout}
                                                                            setCurrentMedia={setCurrentMedia}
                                                                            scrollToBottom={scrollToBottom}
                                                                            setIsMediaSelected={setIsMediaSelected}
                                                                        />
                                                                    </div>
                                                                )
                                                            )}
                                                        </Grid>
                                                    </ListItem>
                                                </Grid>
                                                :
                                                <Grid item xs={12} flexDirection='row' display='flex' paddingLeft='6em'>
                                                    <ListItemText
                                                        align='right'
                                                        primary={c.msg}
                                                        sx={{
                                                            '& .MuiTypography-root': {
                                                                fontWeight: "400",
                                                                fontSize: '16px',
                                                                lineHeight: "21px",
                                                                borderRadius: '12px',
                                                                boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.15)',
                                                                padding: '12px 14px',
                                                                width: 'fit-content',
                                                                textAlign: 'start',
                                                                fontFamily: 'NouvelR',
                                                                wordBreak: 'break-word'
                                                            }
                                                        }}
                                                    />
                                                    <Avatar sx={{ bgcolor: "#ecb100", marginLeft: "10px" }}>
                                                        <img src="images/account.svg" style={{ height: "50%", width: "50%" }} alt="avatar" />
                                                    </Avatar>
                                                </Grid>
                                            }
                                        </Grid>
                                    </ListItem>
                                )}
                            </List>
                        </Grid >
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' alignContent='end' alignItems='flex-end' columnGap='0.5em' flexWrap='nowrap' padding={{ xs: '1em', md: '1em 3em' }}>
                    {/* <Grid item>
                        < LightTooltip title="Pour conserver votre recherche, votre assistant intelligent vous enverra un mail récapitulatif de votre conversation." >
                            <IconButton style={{ padding: 0 }}
                                onClick={() => { setOpenMailModal(true) }}
                                // onClick={() => addMessage('ME', message)} 
                                aria-label="add">
                                <img src="images/doc.svg" alt="file" />
                            </IconButton>
                        </LightTooltip>
                    </Grid> */}
                    <Grid item>

                        <LightTooltip title="Grâce au micro, adressez vous directement à votre assistant intelligent pour trouver le film idéal." >
                            <IconButton style={{ padding: 0 }} disabled={!typingComplete} onClick={recording ? handleStopRecording : handleStartRecording} aria-label="add">
                                <img src={recording ? "images/mic-rec.svg" : "images/mic.svg"} alt={recording ? "stop" : "mic"} />
                            </IconButton>
                        </LightTooltip>
                    </Grid>

                    {recording ?
                        <Grid item flex={1}>

                            <Grid style={{ width: '100%', backgroundColor: 'black', borderRadius: '20px', padding: '8px 15px' }}>
                                <LiveAudioVisualizer
                                    mediaRecorder={mediaRecorder.current}
                                    barColor='white'
                                    height='15px'
                                />
                            </Grid>
                        </Grid> : <><Grid item flex={1}>
                            <TextField
                                placeholder='Écrivez votre message'
                                inputRef={(input) => input && !isMobile && input.focus()}
                                InputProps={{
                                    disableUnderline: true,
                                    maxLength: 200
                                }}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        padding: { xs: '0 13px', md: '0 19px' },
                                    },
                                    '& .MuiInputBase-input::placeholder': {
                                        fontStyle: 'italic',
                                        fontFamily: 'Gill Sans',
                                        fontWeight: 300,
                                        marginLeft: '30px',
                                        fontSize: '16px'
                                    },
                                }}
                                disabled={isTyping}
                                multiline
                                minRows={1}
                                maxRows={4}
                                onChange={handleChangeLocal}
                                onKeyDown={handleKeyPressLocal}
                                value={localMessage}
                                fullWidth
                                style={{ border: '1px solid #000', borderRadius: '20px' }}
                                variant="standard"
                            />
                        </Grid>

                            <Grid item>
                                <IconButton style={{ padding: 0 }}
                                    onClick={() => { addMessage('ME', localMessage); setLocalMessage(''); }}
                                    aria-label="add">
                                    <img src="images/send.svg" alt="arrow" />
                                </IconButton>
                            </Grid></>}


                </Grid>
            </Grid>
        </ThemeProvider >
    )



    const OldChat = (
        <ThemeProvider theme={theme}>
            <Grid container direction='column' style={{ width: '100%', height: '100%', flexWrap: 'nowrap', position: 'relative' }}>
                {
                    isMobile && <Grid style={{ width: '100%', height: '37px', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1, borderRadius: '0 0 10px 10px', backgroundColor: 'rgba(41, 41, 41, 1)' }}>
                        <IconButton style={{ padding: 0 }}
                            onClick={() => {
                                setExpandChat(!expandChat)
                            }}
                            aria-label="expand">
                            <img src="images/arrow_down.svg" alt="expand" style={{ transform: layout === 'home' && 'rotate(180deg)' }} />
                        </IconButton>
                    </Grid>
                }
                <Grid item height={isMobile ? (layout === 'home' && height < 500 ? '70%' : layout === 'fullscreenChat' && height >= 500 ? '85%' : '80%') : '85%'}>
                    <List ref={chatListRef} sx={{ overflowY: 'auto', height: '100%' }} >
                        {chat.map((c, i) =>
                            <ListItem key={i} sx={{ fontFamily: 'NouvelR', marginTop: i === 0 ? '20px' : 0, paddingRight: c.from === "assistant" ? 0 : '' }}>
                                <Grid container>
                                    {c.from === "assistant" ?
                                        <Grid item xs={12}>
                                            <ListItem sx={{ alignItems: 'flex-start', padding: { xs: '8px 0', md: '8px 16px' } }}>
                                                <Avatar sx={{
                                                    bgcolor: "#000",
                                                }}>
                                                    < img src="images/logo_cinematch-green.png" width='35px' alt="avatar" />
                                                </Avatar>
                                                <Grid item xs={10} style={{ padding: '4px 22px', position: 'relative' }}>
                                                    {i === 0 ? <span>{c.msg}</span> : <Typewriter text={c.msg} onTypingUpdate={scrollToBottom} onTypingComplete={() => handleTypingComplete(i)} onTypingStart={handleTypingStart} />}
                                                    {isMobile && layout === 'fullscreenChat' && c?.media && (
                                                        (i === chat.length - 1 ? typingComplete[i] : true) && (
                                                            <div>
                                                                <MediasMobileList
                                                                    medias={c?.media}
                                                                    setLayout={setLayout}
                                                                    setCurrentMedia={setCurrentMedia}
                                                                    scrollToBottom={scrollToBottom}
                                                                    setIsMediaSelected={setIsMediaSelected}
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                                </Grid>
                                            </ListItem>
                                        </Grid>
                                        :
                                        <Grid item xs={12} flexDirection='row' display='flex' paddingLeft='6em'>
                                            <ListItemText
                                                align='right'
                                                primary={c.msg}
                                                sx={{
                                                    '& .MuiTypography-root': {
                                                        fontWeight: "400",
                                                        fontSize: '16px',
                                                        lineHeight: "21px",
                                                        borderRadius: '12px',
                                                        boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.15)',
                                                        padding: '12px 14px',
                                                        width: 'fit-content',
                                                        textAlign: 'start',
                                                        fontFamily: 'NouvelR'
                                                    }
                                                }}
                                            />
                                            <Avatar sx={{ bgcolor: "#ecb100", marginLeft: "10px" }}>
                                                <img src="images/account.svg" style={{ height: "50%", width: "50%" }} alt="avatar" />
                                            </Avatar>
                                        </Grid>
                                    }
                                </Grid>
                            </ListItem>
                        )
                        }
                    </List >
                    {loadingMessage &&
                        <div className="snippet" data-title="dot-flashing" style={{ marginLeft: '4em' }}>
                            <div className="stage">
                                <div className="dot-flashing" />
                            </div>
                        </div>
                    }
                </Grid>
                <Grid item alignContent='center' style={{ backgroundColor: 'white', zIndex: 1, flex: 1, position: 'relative' }}>
                    <Grid item sx={{ position: 'absolute', backgroundColor: 'white', width: '100%', bottom: 'calc(100vh / 30)', display: 'flex', columnGap: 1, padding: { xs: '10px 1em 0', md: '0 3em' }, alignItems: 'center' }}>
                        < LightTooltip title="Pour conserver votre recherche, votre assistant intelligent vous enverra un mail récapitulatif de votre conversation." >
                            <IconButton style={{ padding: 0 }}
                                onClick={() => { setOpenMailModal(true) }}
                                // onClick={() => addMessage('ME', message)} 
                                aria-label="add">
                                <img src="images/doc.svg" alt="file" />
                            </IconButton>
                        </LightTooltip>
                        <LightTooltip title="Grâce au micro, adressez vous directement à votre assistant intelligent pour trouver le film idéal." >
                            <IconButton style={{ padding: 0 }} disabled={!typingComplete} onClick={recording ? handleStopRecording : handleStartRecording} aria-label="add">
                                <img src={recording ? "images/mic-rec.svg" : "images/mic.svg"} alt={recording ? "stop" : "mic"} />
                            </IconButton>
                        </LightTooltip>
                        {recording ?
                            <Grid style={{ width: '100%', backgroundColor: 'black', borderRadius: '20px', padding: '8px 15px' }}>
                                <LiveAudioVisualizer
                                    mediaRecorder={mediaRecorder.current}
                                    barColor='white'
                                    height='15px'
                                /></Grid> : <TextField
                                placeholder='Écrivez votre message'
                                inputRef={(input) => input && !isMobile && input.focus()}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        padding: '6px',
                                        paddingLeft: { xs: '13px', md: '19px' }
                                    },
                                    '& .MuiInputBase-input::placeholder': {
                                        fontStyle: 'italic',
                                        fontFamily: 'Gill Sans',
                                        fontWeight: 300,
                                        marginLeft: '30px',
                                        fontSize: '16px'
                                    },
                                }}
                                disabled={isTyping}
                                onChange={handleChange}
                                onKeyDown={handleKeyPress}
                                value={message}
                                fullWidth
                                style={{ border: '1px solid #000', borderRadius: '30px' }}
                                variant="standard"
                            />}


                        <IconButton style={{ padding: 0 }}
                            onClick={() => addMessage('ME', message)}
                            aria-label="add">
                            <img src="images/send.svg" alt="arrow" />
                        </IconButton>


                    </Grid>
                </Grid>
                <ModalMail onClose={handleCloseModal} open={openMailModal} addMessage={addMessage} />
            </Grid>
        </ThemeProvider >
    )
}

export default Chat;
