import React, { useState, useEffect, useRef } from 'react';

const Typewriter = ({ text, onTypingUpdate, onTypingComplete, onTypingStart }) => {
    const [displayText, setDisplayText] = useState(text[0]);
    const index = useRef(0);

    useEffect(() => {
        if (index.current === 0 && onTypingStart) {
            onTypingStart(); // Notifier le parent lorsque le typage commence
        }

        const tick = () => {
            index.current++;
            setDisplayText((prev) => {
                const updatedText = prev + (text[index.current] || '');
                onTypingUpdate(updatedText); // Notify parent on each update
                if (index.current === text.length - 1) {
                    onTypingComplete(); // Notify parent when typing is complete
                }
                return updatedText;
            });
        }
        if (index.current < text.length - 1) {
            let addChar = setInterval(tick, 25);
            return () => clearInterval(addChar);
        }
    }, [text, onTypingUpdate, onTypingComplete, onTypingStart]);

    return <span style={{
        fontWeight: "400",
        fontSize: '16px',
        lineHeight: '21px'
    }}>{displayText}</span>
};

export default Typewriter