import React, { useState } from 'react';
import { AppBar, Box, Container, IconButton, List, ListItem, ListItemIcon, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useWindowSize from '../UseWindowSize';
import { useTheme, useMediaQuery } from '@mui/material';

const refreshPage = () => {
  window.location.reload();
}

const HeaderLeftItem = ({ iconSrc, title, iconWidth }) => {
  return <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 1 }}>
    <Box>
      <ListItemIcon style={{ display: "block", textAlign: "center" }}>
        <img src={iconSrc} alt='logo' style={{ width: iconWidth }} />
      </ListItemIcon>
    </Box>
    <Typography style={{ fontFamily: "NouvelR", fontSize: '11px', fontWeight: 700, whiteSpace: 'nowrap' }}>{title}</Typography>
  </ListItem>
}

const Header = ({ height }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { text: 'Cinéma', href: '' },
    { text: 'Series', href: '#' },
    { text: "à l'affiche", href: '#' },

  ];

  const leftItems = [
    { iconSrc: "images/icon_favorite.svg", title: 'MA SÉLECTION', width: '19px' },
    { iconSrc: "images/icon_profile.svg", title: 'PROFILE', width: '14px' },
  ]

  return (
    <AppBar position="sticky" sx={{
      backgroundColor: 'black', height: height, justifyContent: 'center'
    }} id='header'>
      < Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="https://demo.worldwidewaib.com/#"
            onClick={refreshPage}
            rel="noopener noreferrer"
            sx={{
              mr: 2,
              display: 'flex',
              fontFamily: 'NouvelR',
              fontWeight: 'bold',
              fontSize: '12px',
              color: 'white',
              textDecoration: 'none',
            }}
          >
            <img src={isMobile ? "images/logo_cinematch-mobile.svg" : "images/logo_cinematch-diago.svg"} alt='logo' style={{ maxWidth: isMobile ? '90px' : '250px' }} />
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="https://airtable.com/app8swlj3Nkvxk9FN/pagh7fBxmM795AKqO/form"
            rel="noopener noreferrer"
            target="_blank"
            sx={{
              ml: 'auto',
              display: 'flex',
              fontFamily: 'NouvelR',
              fontWeight: 'bold',
              fontSize: '15px',
              color: 'white',
              textDecoration: 'none',
            }}
          >
            Nous contacter
          </Typography>

          {/* {menuItems.map((item, index) => (
            <Typography
              key={index}
              variant="h6"
              noWrap
              component="a"
              href={item.href}
              onClick={handleClose}
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'NouvelR',
                fontWeight: 'bold',
                fontSize: '12px',
                color: 'white',
                textTransform: "uppercase",
                textDecoration: 'none',
              }}
            >
              {item.text}
            </Typography>
          ))} */}

          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: 'flex', md: 'none' } }}
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton> */}

          {/* <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                backgroundColor: 'black',
                color: 'white',
                fontFamily: 'NouvelR',
                fontWeight: 'bold',
                fontSize: '12px',
                textTransform: "uppercase",
              },
            }}
          >
            {menuItems.map((item, index) => (
              <MenuItem key={index}>{item.text}</MenuItem>
            ))}
          </Menu> */}
          {/* <List sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}> */}
          {/*<ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <ListItemIcon style={{ display:"block", textAlign:"center"}}>
                <img src="images/recherche.svg"  alt='logo' style={{maxWidth:'3em'}}/>
                </ListItemIcon>
              <Typography  style={{ fontFamily:"NouvelR",fontSize: '12px'}}>recherche</Typography>
            </ListItem>
            <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box>
                <ListItemIcon style={{ display:"block", textAlign:"center"}}>
                <img src="images/car.svg"  alt='logo' style={{maxWidth:'3em'}}/>
                </ListItemIcon>
              </Box>
              <Typography  style={{ fontFamily:"NouvelR",fontSize: '12px'}}>professionnels</Typography>
            </ListItem>
            <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box>
                <ListItemIcon style={{ display:"block", textAlign:"center"}}>
                <img src="images/pin_localisation.svg"  alt='logo' style={{maxWidth:'3em'}}/>
                </ListItemIcon>
              </Box>
              <Typography  style={{ fontFamily:"NouvelR",fontSize: '12px'}}>réseau</Typography>
            </ListItem>
            <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ListItemIcon style={{ display:"block", textAlign:"center"}}>
                <img src="images/user_chat.svg"  alt='logo' style={{maxWidth:'3em'}}/>
                </ListItemIcon>
              <Typography  style={{ fontFamily:"NouvelR",fontSize: '12px', textAlign:"center"}}>contactez-nous</Typography>
            </ListItem>*/}
          {/* {leftItems.map((item) => <HeaderLeftItem iconSrc={item.iconSrc} title={item.title} iconWidth={item.width} />)}
          </List> */}
        </Toolbar>
      </Container >
    </AppBar >
  );
};

export default Header;
