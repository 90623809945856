/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@mui/material";
import React, { useCallback, useEffect, useState, useRef } from 'react';
import CustomFilmGrid from "./mediaFilm";
import CustomImageList from "./media";
import Chat from "./Chat";
import useWebSocket from 'react-use-websocket';
import { useTheme, useMediaQuery } from '@mui/material';



const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [message, setMessage] = useState('');
  const [connexionFailed, setConnexionFailed] = useState(false);
  const [chat, setChat] = useState([{ from: 'assistant', msg: "Bonjour ! Je suis l’assistant intelligent Cinématch. Ma mission aujourd’hui est de vous aider à trouver le film qui correspond parfaitement à vos envies du moment, vos humeurs, vos préférences… Je suis aussi votre expert cinéma de poche capable de répondre à toutes vos interrogations et de vous donner mon avis sur tous les films ! Voici déjà quelques recommandations de films que j’apprécie particulièrement. On trouve ensemble le film qui vous conviendra le mieux ?" }]);
  const [connectionId, setConnectionId] = useState(localStorage.getItem('connectionId'))
  const { sendMessage, lastMessage } = useWebSocket('wss://uc81bvuqd7.execute-api.eu-west-3.amazonaws.com/production/', {
    onOpen: () => {
      console.log('WebSocket connection established.');
      // Send the connection ID if it exists

      if (connectionId) {
        setConnexionFailed(false)
        sendMessage(JSON.stringify({ action: 'reconnect', connectionId }));
      }
    },
    onClose: () => {
      console.log('WebSocket connection closed.');
    },
    onMessage: (message) => {
      const parsedMessage = JSON.parse(message.data);
      if (parsedMessage.connectionId) {
        localStorage.setItem('connectionId', parsedMessage.connectionId);
        setConnectionId(parsedMessage.connectionId);
      }
    },
    shouldReconnect: (closeEvent) => true, // Automatically reconnect
  });
  const [loadingMessage, setloadingMessage] = useState(false);
  const [listeMessages, setListeMessage] = useState([])
  const [listeMedia, setListeMedia] = useState([
    {
      "img": "https://affiches-films.s3.eu-west-3.amazonaws.com/azur_et_asmar_20061025.jpg", "allocine": "film/fichefilm_gen_cfilm=57417.html",
      "title": "Azur et Asmar", "synopsis": `Azur est un blond aux yeux bleus, Asmar un brun aux yeux noirs. Petits, ils se battent et s'aiment comme des frères. Grands, ils se retrouvent rivaux, en quête de la Fée des Djinns, dans un Maghreb médiéval, plein de dangers et de merveilles.`, "Score": 2360, "DiagoScore": 3.8, "duration": "1h 39min", "director": "Michel Ocelot", "actors": "Cyril Mourali, Karim M'Riba, Hiam Abbass", "gender": "Animation, Famille", "releaseDate": "2006/10/25",
      "trailer": "s4sAWhFV_OY", "streamingLinks": [{ "name": "Canal VOD", "url": "https://vod.canalplus.com/cinema/azur-et-asmar/h/1420832_40099" }]
    },
    { "img": "https://affiches-films.s3.eu-west-3.amazonaws.com/simple_comme_sylvain_20231108.jpg", "allocine": "film/fichefilm_gen_cfilm=313750.html", "title": "Simple comme Sylvain", "synopsis": "Sophia est professeure de philosophie à Montréal et vit en couple avec Xavier depuis 10 ans. Sylvain est charpentier dans les Laurentides et doit rénover leur maison de campagne. Quand Sophia rencontre Sylvain pour la première fois, c’est le coup de foudre. Les opposés s'attirent, mais cela peut-il durer ?", "Score": 1622, "DiagoScore": 3.2, "duration": "1h 50min", "director": "Monia Chokri", "actors": "Magalie Lépine Blondeau, Pierre-Yves Cardinal, Francis-William Rhéaume", "gender": "Comédie, Romance", "releaseDate": "2023/11/08", "trailer": "LJEGwAIcKIY", "streamingLinks": [{ "name": "Canal VOD", "url": "https://vod.canalplus.com/cinema/simple-comme-sylvain/h/23236310_40099" }] },
    { "img": "https://affiches-films.s3.eu-west-3.amazonaws.com/blade_runner_19820915.jpg", "allocine": "film/fichefilm_gen_cfilm=1975.html", "title": "Blade Runner", "synopsis": "Dans les dernières années du 20ème siècle, des milliers d'hommes et de femmes partent à la conquête de l'espace, fuyant les mégalopoles devenues insalubres. Sur les colonies, une nouvelle race d'esclaves voit le jour : les répliquants, des androïdes que rien ne peut distinguer de l'être humain.", "Score": 1622, "DiagoScore": 4.8, "duration": "1h 57min", "director": "Ridley Scott", "actors": "Harrison Ford, Rutger Hauer, Sean Young", "gender": "Science Fiction", "releaseDate": "1982/09/15", "trailer": "FfRPKYwsFNg", "streamingLinks": [{ "name": "Canal VOD", "url": "https://vod.canalplus.com/cinema/blade-runner/h/7967473_40099" }] },
    { "img": "https://affiches-films.s3.eu-west-3.amazonaws.com/comme_des_betes_20160727.jpg", "allocine": "film/fichefilm_gen_cfilm=237443.html",  "title": "Comme des bêtes", "synopsis": "La vie secrète que mènent nos animaux domestiques une fois que nous les laissons seuls à la maison pour partir au travail ou à l’école.", "Score": 13622, "DiagoScore": 4, "duration": "1h 27min", "director": "Yarrow Cheney, Chris Renaud", "actors": "Philippe Lacheau, Louis C.K., François Damiens", "gender": "Animation, Comédie, Famille", "releaseDate": "2016/07/27", "trailer": "206EMtKbF58", "streamingLinks": [{ "name": "Canal VOD", "url": "https://vod.canalplus.com/cinema/comme-des-betes/h/7151365_40099" }] }])

  const [currentMedia, setCurrentMedia] = useState(listeMedia);
  const [layout, setLayout] = useState('home')
  const [isRecomandation, setIsRecomandation] = useState(false)
  const [isMediaSelected, setIsMediaSelected] = useState(false)
  const [isUniqueRecomandation, setIsUniqueRecomandation] = useState(false)
  const prevLayoutRef = useRef();



  const addMessage = useCallback(async (from, msg, type = 'text') => {
    //les messages peuvent être de type "text" ou "audio" (qui sont des blobs)
    if (type === 'text') {
      if (msg.trim() === '') return;
      if (from === 'ME') {
        if (listeMessages.length === 0) {
          fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(json => {
              const ipAddress = json.ip;
              sendMessage(JSON.stringify({ "action": "user-message", "content": msg, "ip_address": ipAddress, "content-type": "text" }));
            })
            .catch(err => {
              console.error(`Error getting IP Address: ${err}`);
            });
        }
        else sendMessage(JSON.stringify({ "action": "user-message", "content": msg, "content-type": "text" }));
      }
      setChat(prevChat => [...prevChat, { from, msg }]);
      setMessage('');
    } else
      if (type === 'email') {
        if (msg.trim() === '') return;
        sendMessage(JSON.stringify({ "action": "user-message", "email": msg, "content-type": "text" }));
      }

      else {
        const formData = new FormData();
        formData.append('file', msg);
        formData.append('model', 'whisper-1');
        formData.append('language', 'fr');
        const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
        console.log('formData', apiKey);

        const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${apiKey}`,
          },
          body: formData
        });
        if (response.ok) {
          const data = await response.json();
          if (listeMessages.length === 0) {
            fetch('https://api.ipify.org?format=json')
              .then(response => response.json())
              .then(json => {
                const ipAddress = json.ip;
                sendMessage(JSON.stringify({ "action": "user-message", "content": data.text, "ip_address": ipAddress, "content-type": "text" }));
              })
              .catch(err => {
                console.error(`Error getting IP Address: ${err}`);
              });
          }
          else sendMessage(JSON.stringify({ "action": "user-message", "content": data.text, "content-type": "text" }));
          setChat(prevChat => [...prevChat, { from: 'ME', msg: data.text }]);
          console.log('response', data.text);
        }
      }
  }, [sendMessage]);

  const onClickMedia = useCallback((media_index) => {
    setCurrentMedia(listeMedia[media_index]);
    setIsMediaSelected(true);
  }, [listeMedia]);

  // const onClickChooseMovie = useCallback((film) => {
  //   const msg = `Je souhaite en savoir plus sur le film ${film.title} dont la date de sortie est le ${film.release_date} avec comme sypnosis : "${film.synopsis}". avec comme realisateur ${film.director} et comme acteur principal ${film.actors} disponible sur ${film.streamingLinks?.map(p => p.name).join(', ')}`;
  //   sendMessage(JSON.stringify({ "action": "user-message", "content": msg }));
  //   setChat(prevChat => [...prevChat, { from: 'ME', msg }]);
  //   setloadingMessage(true);
  //   setMessage('');
  // }, [sendMessage]);



  const handleChange = useCallback((event) => {
    setMessage(event.target.value);
  }, []);


  const prevLayout = prevLayoutRef.current

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      addMessage('ME', message);
    }
  };

  useEffect(() => {
    prevLayoutRef.current = layout;
  }, [layout]);


  const onClickMediaClose = useCallback(() => {
    setLayout(prevLayout);
    setCurrentMedia(listeMedia);
    setIsMediaSelected(false);
  }, [listeMedia, prevLayout]);


  useEffect(() => {
    if (connexionFailed) addMessage('assistant', "Désolé, une erreur s'est produite, vous pouvez continuer la conversation ou recharger la page.");
  }, [connexionFailed]);

  useEffect(() => {
    if (lastMessage) {
      const message = JSON.parse(lastMessage.data);
      if (message.role === 'error') setConnexionFailed(true)
      if (message.content !== undefined) {
        if (message.content === "[DONE]") {
          if (listeMessages.length > 0) {
            setListeMessage([]);
          }
        }
        else {
          if (listeMessages.length === 0) {
            setChat(prevChat => [...prevChat, { from: "assistant", msg: message.content }]);
            listeMessages.push(message.content)
          }
          else {
            listeMessages.push(message.content)

            const updatedChat = [...chat];
            if (updatedChat[updatedChat.length - 1].from === "assistant") {
              if (message?.media) {
                updatedChat[updatedChat.length - 1].media = message.media
                setIsRecomandation(true)

              }
              updatedChat[updatedChat.length - 1].msg = listeMessages.join('');
              setChat(updatedChat);
            }
          }
        }
      }
      else {
        if (message.media !== undefined) {
          setListeMedia(message?.media);//?.slice(0, 4));
          setListeMessage([]);

        }
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    setIsUniqueRecomandation(listeMedia.length === 1)
    setCurrentMedia(listeMedia.length === 1 ? listeMedia[0] : listeMedia);
  }
    , [listeMedia])


  const mobileLayoutHandler = {
    home: {
      container: { height: '100%', flexDirection: { xs: 'column-reverse', md: 'row' } },
      media: { height: { 'xs': '30%', 'md': '100%' } },
      chat: { height: { 'xs': '70%', 'md': '100%' } }
    },
    fullscreenMedia: {
      container: { height: '100%', flexDirection: { xs: '', md: 'row' } },
      media: { height: '100%' },
      chat: { display: { xs: 'none', md: 'flex' } }
    },
    fullscreenChat: {
      container: { height: '100%', flexDirection: { xs: 'column', md: 'row' } },
      media: { display: { xs: 'none', md: 'flex' } },
      chat: { height: { 'xs': '100%', 'md': '100%' } }
    }
  }

  return (
    // <Grid container xs style={{ overflow: 'auto' }}>
    <Grid container sx={mobileLayoutHandler[layout].container}>
      <Grid md={6} sx={mobileLayoutHandler[layout].chat} >
        <Chat chat={chat} loadingMessage={loadingMessage} handleChange={handleChange} handleKeyPress={handleKeyPress} message={message} addMessage={addMessage} setLayout={setLayout} layout={layout} setCurrentMedia={setCurrentMedia} setIsMediaSelected={setIsMediaSelected} />
      </Grid>
      <Grid md={6} sx={mobileLayoutHandler[layout].media} >
        {/* {
          currentMedia.length > 2 && <CustomImageList onClickMedia={onClickMedia} listeMedia={listeMedia} setLayout={setLayout} isRecomandation={isRecomandation} />
        }
        {
          !Array.isArray(currentMedia) && <CustomFilmGrid film={currentMedia} handleFilmSelectionClose={onClickMediaClose} />
        } */}
        {
          !isMediaSelected && (isMobile ? true : !isUniqueRecomandation) && <CustomImageList onClickMedia={onClickMedia} listeMedia={listeMedia} setLayout={setLayout} isRecomandation={isRecomandation} />
        }
        {
          (isMediaSelected || (!isMobile && isUniqueRecomandation)) && <CustomFilmGrid film={currentMedia} handleFilmSelectionClose={onClickMediaClose} isUniqueRecomandation={!isMobile && isUniqueRecomandation} />
        }
      </Grid>
    </Grid>
  );
};

export default Home;
