import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.25)',
        fontSize: '13px',
        padding: '14px 16px',
        fontWeight: 400,
        lineHeight: '19px',
        letterSpacing: '-0.03em',
        textAlign: 'left',
        borderRadius: 10
    },
}));

export default Tooltip