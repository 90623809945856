/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState, memo } from 'react';
import { Box, Grid, Typography, IconButton } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { ArrowBack, NoEncryptionGmailerrorred } from '@mui/icons-material';
import Link from '@mui/material/Link';
import ButtonBase from '@mui/material/ButtonBase';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import YouTube from 'react-youtube';
import moment from 'moment';
import { useTheme, useMediaQuery } from '@mui/material';
import 'moment/locale/fr';  // Import the French locale
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

moment.locale('fr');  // Set the locale to French

const theme = createTheme({
  typography: {
    fontFamily: "NouvelR",
  },
});

function getBackgroundColor(platform) {
  switch (platform) {
    case 'Netflix':
      return '#E50914';
    case 'Prime Video':
      return '#00A8E1';
    case 'Disney+':
      return '#008DD5';
    case 'Apple TV':
      return '#000000';
    case 'Canal VOD':
      return '#0078FF';
    default:
      return 'black';
  }

}

const getPictoStreamLink = (linkName) => {
  switch (linkName) {
    case 'Canal VOD':
      return 'picto_canal-vod';
    case 'Canal+':
      return 'picto_canal-plus';
    case 'Netflix':
      return 'picto_netflix';
    case 'Apple TV+':
      console.log("APPLE TV");
      return 'picto_appletv';
    case 'Amazon Prime Video':
      return 'picto_prime';
    default:
      return '';
  }
}

const StreamLink = ({ name, url }) => {
  return (
    <Link href={url} color="inherit" underline="none" target="_blank" rel="noopener">
      <Grid item padding={0}>
        <Grid item container columnGap={1}>
          <Grid item display='flex' alignItems='center'>
            <img src={`images/${getPictoStreamLink(name)}.svg`} alt='vod' height='20px' />
          </Grid>
          <Grid item >
            <Typography fontSize={12} align='center'>
              {name}
            </Typography>
            <Typography fontSize={12} align='center'>
              dès <b>2,99€</b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Link>
  )
}

const CustomFilmGrid = memo(({ film, handleFilmSelectionClose, isUniqueRecomandation }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [isPlaying, setIsPlaying] = useState(false);





  if (Array.isArray(film)) {
    // Check if new request has been send and more movies from the chat
    handleFilmSelectionClose()
  }

  const InfosWebLayout = () => {
    return (
      <>
        <Grid item display='flex' justifyContent='center' paddingBottom='1em'>
          <Typography style={{ fontWeight: 700, fontSize: '29px' }}>
            {film?.title}
          </Typography>
        </Grid>
        <Grid container>
          <Grid flex={1} item >
            <img
              src={film?.img}
              style={{ width: "100%" }}
              alt={film?.title}
              onError={(e) => { e.target.onerror = null; e.target.src = "https://affiches-films.s3.eu-west-3.amazonaws.com/cinematch_casse.png" }}
            ></img>
            <Typography fontSize={10} style={{ textAlign: "center" }}>
              <a href={"https://allocine.fr/" + film?.allocine} target="_blank"  style={{ color: "inherit" }}>Lire sur Allociné</a>
            </Typography>
          </Grid>
          <Grid flex={2} item padding='5px 12px'>
            <Typography fontSize={12}><b><u>Date de sortie</u></b> : {moment(film?.releaseDate, "YYYY/MM/DD").format("D MMMM YYYY")} | {film?.duration} | {film?.gender} <br /><br />
              De <b>{film?.director}</b> <br />
              Avec <b>{film?.actors}</b></Typography>
            {(film?.DiagoScore) && <Grid direction='column' style={{ border: '1px solid black', borderRadius: '10px', padding: '6px 10px', width: '60px', height: '60px', alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: '15px' }}>
              <Typography fontSize={10}><b>Note</b>
              </Typography>
              <Typography fontSize={24} fontWeight={700} style={{ color: 'rgba(102, 255, 0, 1)' }}>
                {film?.DiagoScore}
              </Typography>
            </Grid>}
            <br />
            <Typography fontSize={12}>
              <b><u>Synopsis</u></b> :<br />
              {film?.synopsis}
            </Typography>
          </Grid>
        </Grid>
      </>
    )
  }

  const InfosMobileLayout = () => {
    const [showMore, setShowMore] = useState(false);
    const synopsisShort = film?.synopsis.substring(0, 200);

    const toggleShowMore = () => {
      setShowMore(!showMore);
    };

    const ScoreAndSynopsis = () => {
      return (
        <>
          {(film?.DiagoScore) && <Grid direction='column' style={{ border: '1px solid black', borderRadius: '10px', padding: '6px 10px', width: '60px', height: '60px', alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: '15px' }}>
            <Typography fontSize={10}><b>Note</b>
            </Typography>
            <Typography fontSize={24} fontWeight={700} style={{ color: 'rgba(102, 255, 0, 1)' }}>
              {film?.DiagoScore}
            </Typography>
          </Grid>}
          <br />
          <Typography fontSize={14} sx={{
          }}>
            <b><u>Synopsis</u></b> :<br />
            {showMore ? film?.synopsis : synopsisShort}
            {synopsisShort.length !== film?.synopsis.length && <Link onClick={toggleShowMore} style={{ color: 'black', fontSize: '14px', fontWeight: 'bold', textDecoration: 'none' }}>
              {showMore ? ' Lire moins...' : ' Lire plus...'}
            </Link>}
          </Typography>
        </>
      )
    }

    return (
      <>
        <Grid container justifyContent='flex-end' padding='10px 0 0' style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
          <ButtonBase onClick={handleFilmSelectionClose}>
            <CloseRoundedIcon fontSize='large' />
          </ButtonBase>
        </Grid>
        <Grid container>
          <Grid flex={3} item >
            <img
              src={film?.img}
              style={{ width: "100%" }}
              alt={film?.title}
              onError={(e) => { e.target.onerror = null; e.target.src = "https://affiches-films.s3.eu-west-3.amazonaws.com/cinematch_casse.png" }}
            ></img>
          </Grid>
          <Grid flex={4} item padding='0 12px'>
            <Typography style={{ fontWeight: 700, fontSize: '18px' }}>
              {film?.title}<br /><br />
            </Typography>
            <Typography fontSize={14}><b><u>Date de sortie</u></b> : {moment(film?.releaseDate, "YYYY/MM/DD").format("D MMMM YYYY")} | {film?.duration} | {film?.gender} <br /><br />
              De <b>{film?.director}</b> <br />
              Avec <b>{film?.actors}</b></Typography>
            {!isMobileSmall && <ScoreAndSynopsis />}
            <br />
            <Typography fontSize={14}>
              <a href={"https://allocine.fr/" + film?.allocine} target="_blank"  style={{ color: "inherit" }}>Lire sur Allociné</a>
            </Typography>
          </Grid>
        </Grid>
        {isMobileSmall && <ScoreAndSynopsis />}
      </>
    )
  }

  const FilmTrailer = () => {
    const borderRadius = '12px';

    const opts = {
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: 1,
      },
    };

    const onPlayButtonClick = () => {
      setIsPlaying(true);
    };

    const onReady = (event) => {
      const iframe = event.target.getIframe();
      iframe.style.borderRadius = borderRadius;
    };


    return (
      <>
        {film?.trailer && film?.trailer !== "" && (
          <Grid
            style={{
              backgroundColor: 'black',
              position: 'relative',
              width: '100%',
              height: 0,
              paddingBottom: '56.25%',
              cursor: 'pointer',
              borderRadius: borderRadius,
              overflow: 'hidden'
            }}
            onClick={onPlayButtonClick}
          >
            {isPlaying ? (
              <YouTube
                videoId={film.trailer}
                opts={opts}
                style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
              />
            ) : (
              <>
                <img
                  src={`https://img.youtube.com/vi/${film.trailer}/maxresdefault.jpg`}
                  alt="video thumbnail"
                  style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, borderRadius: borderRadius }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '64px',
                    height: '64px',
                    background: 'rgba(0,0,0,0.5)',
                    borderRadius: '50%',
                    display: 'flex',
                    borderRadius: '12px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ width: '32px', height: '32px', color: '#fff' }}
                  >
                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                  </svg>
                </div>
              </>
            )}
          </Grid>
        )}
      </>
    );
  };


  return (
    <ThemeProvider theme={theme}>
      <Grid container direction='column' height='100%' sx={{ flexWrap: 'nowrap', backgroundColor: { xs: 'rgba(43, 43, 43)', md: 'rgba(245, 245, 245, 1)' }, padding: { xs: '16px 0 0', md: !isUniqueRecomandation ? '0 16px 4em' : '0 16px 2em' } }}>
        {!isMobile && <ButtonBase onClick={() => handleFilmSelectionClose()}>
          <Grid flex={1} container direction='row' padding='10px 0px'>
            {!isUniqueRecomandation && <>
              <ChevronLeftIcon style={{ color: 'rgba(102, 255, 0, 1)' }} />
              <Typography style={{ fontSize: '16px', fontWeight: 600 }}>RETOUR</Typography>
            </>}
          </Grid>
        </ButtonBase>}
        <Grid container direction='column' height={{ xs: '100%', md: '100%' }} sx={{ padding: { xs: '0', md: '10px 0' }, backgroundColor: 'white', borderRadius: { xs: '12px' } }}>
          <Grid item direction='column' sx={{ flex: 1, overflowY: 'auto', backgroundColor: 'white', padding: '0 16px 30px', borderRadius: '12px 12px 0 0' }}>
            {isMobile ? <InfosMobileLayout /> : <InfosWebLayout />}
            <Grid container justifyContent='space-around' height='75px' alignItems='center' flexWrap='nowrap'>
              {
                film?.streamingLinks?.map((link) =>
                  <StreamLink name={link.name} url={link.url} />
                )
              }
            </Grid>
            <FilmTrailer />
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider >
  )
})

export default CustomFilmGrid
