/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import { Box, Grid } from "@mui/material";
// import ImageList from '@mui/material/ImageList';
// import ImageListItem from '@mui/material/ImageListItem';
// import ImageListItemBar from '@mui/material/ImageListItemBar';
import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { ArrowBack, Padding } from '@mui/icons-material';
import useWindowSize from '../../../components/UseWindowSize';
import CustomFilmGrid from "../mediaFilm";
import FilmHorizontalCard from '../../../components/FilmHorizontalCard';
import { useTheme, useMediaQuery } from '@mui/material';



export default function CustomImageList({ onClickMedia, listeMedia, setLayout, isRecomandation }) {
  const { width } = useWindowSize();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // console.log({ listeMedia })
  // console.log({ isRecomandation })

  const MobileLayout = () => {

    const handleClick = (item, index) => {
      if (item !== undefined) {
        setLayout('fullscreenMedia')
        onClickMedia(index)
      }
    }

    return (
      <Grid container style={{ position: 'absolute', overflowX: 'auto', whiteSpace: 'nowrap', width: '100%', height: '100%', zIndex: 1, backgroundColor: '#1f1f1f' }}>
        <Grid container sx={{ flexWrap: 'nowrap', direction: 'rtl', width: 'auto', height: '100%', margin: '0 auto', justifyContent: 'space-around', columnGap: width / 150 }}>
          {/* <Grid container sx={{ flexWrap: 'nowrap', width: '100%', height: '100%' }}> */}
          {listeMedia?.map((item, index) =>
            <img
              key={index}
              onClick={() => handleClick(item, index)}
              src={item.img}
              height='100%'
              width='auto'
              onError={(e) => { e.target.onerror = null; e.target.src = "https://affiches-films.s3.eu-west-3.amazonaws.com/cinematch_casse.png" }}
            />
          )}
        </Grid>
      </Grid >
    )
  }

  const MediaLayout = () => {
    return (
      <>
        <Grid xs={listeMedia.length === 4 ? 3 : 4} item rowGap={3} columnGap={3} style={{ height: '100%' }}>
          <ImageList gap={3} cols={1} style={{ margin: 0, height: '100%' }}>
            {listeMedia?.map((item, index) => index < listeMedia.length - 1 &&
              <ImageListItem key={item.img} style={{ cursor: 'pointer' }} onClick={() => item !== undefined ? onClickMedia(index) : ''}>
                <img
                  src={item.img}
                  alt={item.title}
                  loading="lazy"
                  onError={(e) => { e.target.onerror = null; e.target.src = "https://affiches-films.s3.eu-west-3.amazonaws.com/cinematch_casse.png" }}
                />
              </ImageListItem>
            )}
          </ImageList>
        </Grid>
        <Grid xs item style={{ height: '100%' }}>
          <ImageList cols={1} rows={1} style={{ margin: 0, height: '100%' }}>
            {listeMedia[listeMedia.length - 1] &&
              <ImageListItem key={listeMedia[listeMedia.length - 1].img} style={{ cursor: 'pointer' }} onClick={() => listeMedia[listeMedia.length - 1] !== undefined ? onClickMedia(listeMedia.length - 1) : ''}>
                <img
                  src={listeMedia[listeMedia.length - 1].img}
                  alt={listeMedia[listeMedia.length - 1].title}
                  style={{ width: "100%", height: '100%' }}
                  loading="lazy"
                  onError={(e) => { e.target.onerror = null; e.target.src = "https://affiches-films.s3.eu-west-3.amazonaws.com/cinematch_casse.png" }}
                />
              </ImageListItem>
            }
          </ImageList>
        </Grid>
      </>
    )
  }

  const MediaLayout2 = () => {
    // if (!isMobile && listeMedia?.length === 1) {
    //   console.log('CLICK AUTO 1 MEDIA')
    //   onClickMedia(0)
    // }
    return (
      <Grid container direction='row' justifyContent='center' alignItems='flex-end' style={{ height: '100%', position: 'relative', backgroundColor: 'rgba(49, 49, 49, 1)' }}>
        <Grid container style={{ position: 'absolute', overflowY: 'auto', whiteSpace: 'nowrap', width: '95%', height: '95%' }}>
          <Grid container sx={{ flexWrap: 'nowrap', flexDirection: 'column', rowGap: 2 }}>
            {listeMedia?.map((item, index) =>
              <div key={index} onClick={() => item !== undefined ? onClickMedia(index) : ''} style={{ width: '100%', height: 'auto', backgroundColor: 'white', borderRadius: '12px', cursor: 'pointer' }}>
                <FilmHorizontalCard film={item} />
              </div>
            )}
          </Grid>
        </Grid >
      </Grid>
    )
  }

  return (
    <Grid container direction='row' style={{ height: '100%', position: 'relative' }}>
      {
        listeMedia?.length > 0 &&
        (
          isMobile ?
            <MobileLayout /> : isRecomandation ? <MediaLayout2 /> : <MediaLayout />
        )
      }
    </Grid>
  );
}

